

































































import { Component, Vue } from 'vue-property-decorator';
import EnvLabel from '@/components/app/EnvLabel.vue';
import ApiService from '@/services/api';
import { JWTUtils } from '@/utils';

@Component({
  components: {
    EnvLabel,
  },
})
export default class Login extends Vue {
  $refs!: {
    loginForm: Vue & { validate: () => boolean };
  };

  username = '';

  password = '';

  errorMessage = '';

  isLoggingin = false;

  async login(): Promise<void> {
    if (!this.$refs.loginForm.validate()) {
      return;
    }
    try {
      this.errorMessage = '';
      this.isLoggingin = true;
      const { username, password } = this;
      const response = await ApiService.login({ username, password });
      if (response.Token) {
        // Save token to local storage
        JWTUtils.saveToken(response.Token);
        // Redirect to destination page
        const { redirect } = this.$route.query;
        this.$router.push(redirect ? (redirect as string) : '/');
      } else {
        throw response.Message;
      }
    } catch (error) {
      this.errorMessage = typeof error === 'string' ? error : '發生未知的錯誤';
    } finally {
      this.isLoggingin = false;
    }
  }
}
